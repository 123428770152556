<template>
  <training-plan ref="planComponent" :client="client" :plan="trainingPlan" :has-back="true" />
</template>

<script>
import Utils from '@/services/Utils'

export default {
  components: {
    TrainingPlan: () => import('@/components/dashboard/trainingPlan/TrainingPlan'),
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.$refs.planComponent && this.$refs.planComponent.destroyValidation) {
      this.$refs.planComponent.destroyValidation(next)
    }
  },
  data() {
    const data = Utils.getStorage('trainingPlan')
    const client = data ? data.client : {}
    const trainingPlan = data ? data.data : { name: '', days: [] }
    return {
      trainingPlan: trainingPlan,
      client: client,
    }
  },
}
</script>
